
<template>
  <div class="pvhFactory">
    <basic-container>
      <div class="layTop">
        <div class="Title" style="margin-bottom:20px;">{{$t('FieldSettingList')}}</div>
      </div>
      <template>
        <avue-crud ref="crud" v-model="obj" :option="setData.tableOpt" :data="tableData" :row-style="rowStyle" :page.sync="page" @current-change="currentChange" @row-update="handleUpdate" @row-del="delField" @row-save="handleSave">
          <template slot="menuRight" style="width: calc(100% - 80px);">
            <el-row :gutter="20" class="topFromSearch">
                <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                    <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                        <span>{{$t('NAME')}}:</span>
                        <el-input v-model="cnameVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                    </el-col>
                    <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                        <span id="venSpan">{{$t('DATATYPE')}}:</span>
                        <el-select v-model="dataTypeVal.ids" @focus="focusDiv($event)" @blur="blurDiv($event)" multiple collapse-tags placeholder="" style="width:100%;" @change="venchang">
                            <el-option v-for="item in this.typeData"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                        <span>{{$t('BEINGTIME')}}:</span>
                        <el-date-picker class="dataicon" v-model="beginTimeVal"
                                        type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                        format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                        </el-date-picker>
                    </el-col>
                    <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                        <span>{{$t('ENDTIME')}}:</span>
                        <el-date-picker class="dataicon" v-model="endTimeVal"
                                        type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                        format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                        </el-date-picker>
                    </el-col>
                    <!--<el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="justify-content:flex-start;height:38px;align-items:center;display:flex;">
                        <el-checkbox v-model="isNullVal" class="othChe">{{$t('ISNULL')}}</el-checkbox>
                    </el-col>-->
                </el-col>
                <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4"  style="padding-top: 25px; text-align: right; align-self: flex-end;">
                    <el-button type="primary" size="small"  @click="searchFrom">{{$t('SEARCH')}}</el-button>
                    <el-badge>
                        <img style="padding-top: 4px; padding-right: 15px; padding-left: 15px; height: 1.7rem; width: 1.7rem;" @click="$refs.crud.rowAdd()"
                             :title="$t('ADD')" src="/img/Bnt/add.png" />
                    </el-badge>
                </el-col>
            </el-row>
          </template>
          <template slot-scope="{row,index,size,type}" slot="menu">
            <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" @click="$refs.crud.rowEdit(row,index)"></el-button>
          </template>
          <template slot-scope="{row,index,type}" slot="menuForm">
            <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'" @click="$refs.crud.rowSave()">{{$t("Hold")}}</el-button>
            <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'" @click="$refs.crud.rowUpdate()">{{$t("Hold")}}</el-button>
            <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">{{$t("Cancel")}}</el-button>
          </template>
        </avue-crud>
      </template>
    </basic-container>
  </div>
</template>
<style>

.dataicon i {
  display: none;
}

.othChe .el-checkbox__label {
  color: #409eff !important;
}

</style>


<script>
import { editField, fieldList, addField, delField } from "@/api/fieldList";
import { TracingDropDownList, TracingUnifiedIsBool } from "@/api/unified";
export default {
  data() {
    return {
      obj: {},
      cnameVal: "",
      dataTypeVal: {
        ids: [],
      },
      isNullVal: false,
      beginTimeVal: "",
      endTimeVal: "",
      tableData: [],
      YesNoData: [
        {
          label: this.$t('False'),
          value: 0
        }, {
          label: this.$t('True'),
          value: 1
        }

      ],
      typeData: [
        {
          label: this.$t('Input'),
          value: "1"
        },
        {
          label: this.$t('InputNumber'),
          value: "2"
        },
        {
          label: this.$t('Select'),
          value: "3"
        },
        {
          label: this.$t('DatePicker'),
          value: "4"
        },
        {
          label: this.$t('Radio'),
          value: "5"
        },
        {
          label: this.$t('Checkbox'),
          value: "6"
        }
      ],
      sourceData:[],
      page: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev,next",
        background: true,
        pageSize: 10
      }
    }
  },
  computed: {
    setData() {
      var validateRequired = (rule, value, callback) => {
        if ((value == '' || value == null) && (this.obj.dataType == "3" || this.obj.dataType == "6")) {
          callback(new Error(this.$t('SelectDataSource')));
        } else {
          callback();
        }
      };
      var validateExist = (rule, value, callback) => {
        if ((value != '' || value != null)) {
          var ids = this.obj.id;
          if (this.obj.id == undefined)
          {
            ids = "";
          }
          TracingUnifiedIsBool("FieldExist", ids, value).then(resu => {
            var IsExist = resu.data;
            if (!IsExist) {
              callback(new Error(this.$t('NameIsExist')));
            }
            else { callback();
            }
          }).catch(() => { callback();});
        } else {
          callback();
        }
      };
      var validateCodeExist = (rule, value, callback) => {
        if ((value != '' || value != null)) {
          var ids = this.obj.id;
          if (this.obj.id == undefined) {
            ids = "";
          }
          TracingUnifiedIsBool("FieldCodeExist", ids, value).then(resu => {
            var IsExist = resu.data;
            if (!IsExist) {
              callback(new Error(this.$t('CodeIsExist')));
            } else { callback(); }
          }).catch(() => { callback(); });
        } else {
          callback();
        }
      };
      return {
        tableOpt: {
          page: true,
          refreshBtn: false,
          delBtn: false,
          emptyText: this.$t('No Data'),
          menuTitle: this.$t('OPERATION'),
          menuWidth: 140,
          addBtnText: this.$t("ADD"),
          align: 'center',
          columnBtn: false,
          simplePage: false,
          editTitle: this.$t('Edit'),
          addTitle: this.$t('Add'),
          addBtn: false,
          editBtn: false,
          header: true,
          saveBtn: false,
          updateBtn: false,
          cancelBtn: false,
          labelPosition: 'top',
          column: [
            {
              label: this.$t('NAME'),
              prop: 'name',
              order: 1,
              rules: [{ required: true, message: this.$t('Required'), trigger: "blur" },
                { required: true, validator: validateExist, trigger: "blur" }]
            },
            {
              label: this.$t('CODE'),
              prop: 'code',
              rules: [{ required: true, message: this.$t('Required'), trigger: 'blur' },
                { validator: validateCodeExist, trigger: 'blur' }]
            },
            {
              label: this.$t('DATATYPE'),
              prop: 'dataType',
              type: "select",
              filterable: true,
              dicData: this.typeData,
              rules: [{
                required: true,
                message: this.$t('Pleaseselect'),
                trigger: "blur"
              }],
            },
            {
              label: this.$t('DataSource'),
              prop: 'dataSource',
              type: "select",
              filterable: true,
              dicData: this.sourceData,
              rules: [{ required: true, validator: validateRequired, trigger: "blur"}],
            },
            {
              label: this.$t('ISNULL'),
              prop: 'isNull',
              type: "switch",
              dicData: this.YesNoData,
            },
            {
              label: this.$t('CREATTIME'),
              prop: 'createTime',
              type: "date",
              editDisplay: false,//页面 隐藏
              addDisplay: false,
              format: "yyyy-MM-dd HH:mm:ss",
              valueFormat:"yyyy-MM-dd HH:mm:ss"
            },
          ]
        }
      }
    }
  },
  created() {
    TracingDropDownList('EnumParentList').then(res => {
      this.sourceData = res.data;
    });
    this.getFieldList();
  },
  methods: {
    moreFrom() {
      let fromHei = document.querySelector(".fromOutDiv").clientHeight;
      if (fromHei > 60) {
        document.querySelector(".fromOutDiv").style.cssText = "height:60px;";
      } else {
        document.querySelector(".fromOutDiv").style.cssText = "height:auto;";
      }
    },
    focusDiv(obj) {//搜索框获取焦点
      if (obj.currentTarget === undefined) {
        obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      } else {
        if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
          obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
        } else {
          obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
        }

      }
    },//搜索框失去焦点
    blurDiv(obj) {
      if (obj.currentTarget === undefined) {
        if (obj.value === "") {
          obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      } else if (obj.currentTarget === null) {
        if (this.dataTypeVal.ids.length === 0) {
          document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      } else {
        if (obj.target.value == "") {
          obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      }
    },
    venchang() {
      if (this.dataTypeVal.ids.length === 0) {
        document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
      } else {
        document.querySelector("#venSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      }
    },//点击更多按钮
    rowStyle({rowIndex }) {
      if (rowIndex % 2 === 0) {
        return {
          backgroundColor: '#F8F8F8'
        }
      }
    },//切换分页
    currentChange(val) {
      this.page.currentPage = val
      this.getFieldList();
    },
    //搜索
    searchFrom() {
      this.getFieldList();
    },//添加
    handleSave(row, done) {
      if (row.isNull == '')
      {
        row.isNull=0
      }
      if ((row.dataType == "3" || row.dataType == "6" || row.dataType == "5") && row.dataSource == '')
      {
        this.$message({ showClose: true, message: this.$t('SelectDataSource'), type: "warning" });
        return;
      }
      addField(row).then(() => {
        this.$message({ showClose: true, message: this.$t('AddSuccess'), type: "success" });
        this.getFieldList();
        this.obj = {};
        done();
      })
    },
    handleUpdate(row, done) {
      if (row.isNull == '') {
        row.isNull = 0
      }
      if ((row.dataType == "3" || row.dataType == "6" || row.dataType == "5") && row.dataSource == '') {
        this.$message({ showClose: true, message: this.$t('SelectDataSource'), type: "warning" });
        return;
      }
      editField(row).then(() => {
        this.$message({ showClose: true, message: this.$t('UpdateSuccess'), type: "success" });
        this.getFieldList();
        this.obj = {};
      });
      done();
    },//删除
    delField(row, done) {
      this.$confirm(this.$t('IsDel') + row.name, this.$t('Tips'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        type: "warning"
      }).then(() => {
        delField(row.id).then(() => {
          this.getFieldList();
          this.$message({
            showClose: true,
            message: this.$t('DeleteSuccess'),
            type: "success"
          });
          done();
        })
      })
    },//显示
    async getFieldList() {
      await fieldList(this.page.currentPage, this.page.pageSize, this.cnameVal, this.dataTypeVal.ids.toString(), this.isNullVal,this.beginTimeVal, this.endTimeVal ).then(res => {
        this.tableData = res.data.supportFields;
        this.page.total = res.data.totalCount;
        this.page.pageSize = res.data.pageSize;
      }).catch(erro => {
        console.log(erro);
      })
    },
  },
}
</script>
